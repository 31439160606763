export const colors = {
  brand: {
    100: "#021482",
    200: "#335EEA",
    300: "#021B66",
  },
  primary: {
    100: "#cae9ff",
    200: "#017FD9",
  },
};
