import {Box, Stack} from "@chakra-ui/react";
import Header from "../components/header";
import Footer from "../components/footer";

type Props = {children: React.ReactNode};

const MainLayout = (props: Props) => {
  const {children} = props;
  return (
    <Box>
      <Header />
      <Stack
        justifyContent="space-between"
        minHeight="100dvh"
        paddingTop={"6rem"}
        spacing={20}
      >
        {children}
        <Footer />
      </Stack>
    </Box>
  );
};

export default MainLayout;
