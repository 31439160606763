"use client";
import React, {useState} from "react";
import Link from "next/link";
import {RiArrowDownSLine} from "@onemind-services-llc/react-icons-ng/ri";
import {
  Box,
  HStack,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {SERVICES_CONSTANTS} from "./services.constant";
import {usePathname} from "next/navigation";

const Services = (props: {onLinkClick?: () => void}) => {
  const {onLinkClick = () => {}} = props;
  const {isOpen, onClose, onOpen} = useDisclosure();
  const pathname = usePathname();
  const [selectedValue, setSelectedValue] = useState(SERVICES_CONSTANTS[0].nestedRoutes);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    onClose();
    onLinkClick();
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <HStack cursor={"pointer"}>
          <Text variant={TEXT_VARIANTS.BODY1}>Services</Text>
          <Icon as={RiArrowDownSLine} />
        </HStack>
      </PopoverTrigger>
      <PopoverContent w={"full"} autoFocus={false}>
        <PopoverArrow />
        <SimpleGrid columns={{base: 1, sm: 2}} p={{base: 2, sm: 4}} spacing={4}>
          <Stack bgColor={"gray.100"} py={{base: 2, sm: 4}}>
            {SERVICES_CONSTANTS.map((item, index) => (
              <Box
                borderColor="brand.100"
                borderRightWidth={index === selectedIndex ? "3px" : "0px"}
                cursor={"pointer"}
                key={index}
                px={4}
                py={2}
              >
                {item.nestedRoutes ? (
                  <Text
                    color={index === selectedIndex ? "brand.200" : ""}
                    onClick={() => {
                      setSelectedValue(item.nestedRoutes);
                      setSelectedIndex(index);
                    }}
                    variant={TEXT_VARIANTS.BODY1}
                  >
                    {item.name}
                  </Text>
                ) : (
                  <ChakraLink
                    as={Link}
                    cursor={"pointer"}
                    href={item.path}
                    onClick={handleClick}
                    _hover={{color: "brand.200"}}
                  >
                    <Text variant={TEXT_VARIANTS.BODY1}>{item.name}</Text>
                  </ChakraLink>
                )}
              </Box>
            ))}
          </Stack>
          <Box>
            <Stack px={4} py={{base: 2, sm: 4}} spacing={6}>
              {selectedValue &&
                selectedValue.map((item, index) => (
                  <HStack
                    as={Link}
                    color={pathname === item.path ? "brand.200" : ""}
                    href={item.path}
                    key={index}
                    onClick={handleClick}
                    spacing={4}
                    target={item.is_external ? "_blank" : ""}
                    _hover={{color: "brand.200"}}
                  >
                    <Icon as={item.icon} boxSize={8} />
                    <Text variant={TEXT_VARIANTS.BODY2}>{item.name}</Text>
                  </HStack>
                ))}
            </Stack>
          </Box>
        </SimpleGrid>
      </PopoverContent>
    </Popover>
  );
};

export default Services;
