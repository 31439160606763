import {
  BUSINESS_RISK_SERVICES_ROUTES,
  CLOUD_SERVICE_ROUTES,
  INTERNET_SERVICES_ROUTES,
  MAIN_ROUTES,
  SERVICES_ROUTES,
  VOICE_SERVICE_ROUTES,
} from "@/constants/routes";
import {
  AiOutlineDatabase,
  AiOutlineCloudServer,
} from "@onemind-services-llc/react-icons-ng/ai";
import {
  BsCloudHail,
  BsLaptop,
  BsCloudCheck,
  BsCloudFog,
  BsCloudDownload,
  BsShield,
  BsFillPeopleFill,
} from "@onemind-services-llc/react-icons-ng/bs";
import {FlFillIcFluentPeopleTeam24Filled} from "@onemind-services-llc/react-icons-ng/fl";
import {GrCloudComputer} from "@onemind-services-llc/react-icons-ng/gr";
import {SlGlobe} from "@onemind-services-llc/react-icons-ng/sl";
import {TfiPrinter} from "@onemind-services-llc/react-icons-ng/tfi";

export const SERVICES_CONSTANTS = [
  {
    name: "Voice Services",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        icon: FlFillIcFluentPeopleTeam24Filled,
        name: "Unified Communications",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.VOICE_SERVICES}${VOICE_SERVICE_ROUTES.UNIFIED_COMMUNICATIONS}`,
      },
      {
        icon: BsCloudHail,
        name: "Cloud Hosted PBX",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.VOICE_SERVICES}${VOICE_SERVICE_ROUTES.CLOUD_HOSTED_PBX}`,
      },
      {
        icon: TfiPrinter,
        name: "Cloud FAX",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.VOICE_SERVICES}${VOICE_SERVICE_ROUTES.CLOUD_FAX}`,
      },
    ],
  },
  {
    name: "Internet Services",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        icon: AiOutlineDatabase,
        name: "DataCenter Connectivity",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.DATA_CENTER_CONNECTIVITY}`,
      },
      {
        icon: SlGlobe,
        name: "Business Internet",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.BUSINESS_INTERNET}`,
      },
      {
        icon: BsLaptop,
        name: "IP TV Solutions",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.IP_TV_SOLUTIONS}`,
      },
    ],
  },
  {
    name: "Cloud Services",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        icon: AiOutlineCloudServer,
        name: "Cloud Strategy",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.CLOUD_STRATEGY}`,
      },
      {
        icon: BsCloudDownload,
        name: "Cloud Application",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.CLOUD_APPLICATION}`,
      },
      {
        icon: BsLaptop,
        name: "Observability",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.OBSERVABILITY}`,
      },
      {
        icon: BsCloudFog,
        is_external: true,
        name: "POC Cloud",
        path: "https://cloudmylab.com/",
      },
      {
        icon: GrCloudComputer,
        is_external: true,
        name: "Public Cloud",
        path: "https://cloudmydc.com/",
      },
    ],
  },
  {
    name: "Business Risk Services",
    path: MAIN_ROUTES.HOME,
    nestedRoutes: [
      {
        icon: BsShield,
        name: "Security Technologies",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.SECURITY_TECHNOLOGIES}`,
      },
      {
        icon: BsCloudCheck,
        name: "DRaas",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.DRAAS}`,
      },
      {
        icon: BsFillPeopleFill,
        name: "Infosec Training",
        path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.INFOSEC_TRAINING}`,
      },
    ],
  },
  {
    name: "Internet of Things",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.IOT}`,
  },
  {
    name: "Professional Services",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROFESSIONAL_SERVICES}`,
  },
];
