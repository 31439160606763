import Link from "next/link";
import {Heading, Stack, Text} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, SERVICE_OPTIONS} from "../footer.constants";

const FooterService = () => {
  return (
    <Stack spacing={{base: 2, sm: 4, md: 6}}>
      <Heading variant={HEADING_VARIANTS.SUBTITLE3}>{FOOTER_CONSTANTS.SERVICES}</Heading>
      <Stack>
        {SERVICE_OPTIONS.map((option) => {
          const {name, path} = option;
          return (
            <Link href={path} key={path}>
              <Text color="#021b66">{name}</Text>
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FooterService;
