import {
  BUSINESS_RISK_SERVICES_ROUTES,
  CLOUD_SERVICE_ROUTES,
  INTERNET_SERVICES_ROUTES,
  MAIN_ROUTES,
  SERVICES_ROUTES,
  VOICE_SERVICES_ROUTES,
} from "@/constants/routes";
import {CoMailOpen} from "@onemind-services-llc/react-icons-ng/co";
import {FaLocationArrow, FaPhoneAlt} from "@onemind-services-llc/react-icons-ng/fa";
import {MdOutlineSupport} from "@onemind-services-llc/react-icons-ng/md";

export const FOOTER_CONSTANTS = {
  BRAND_TEXT:
    "“Our Expertise, Your Success is our Mission Statement. We are Customer Experts.”",
  CONTACT_INFO: "CONTACT INFO",
  DONT_MISS_UPDATES: "Don't miss our future updates. Get subscribed today !",
  INTERNET_SERVICE: "INTERNET SERVICES",
  POLICIES: "POLICIES",
  RIGHTS: `© 2016 - ${new Date().getFullYear()} | All rights reserved by OneMind Services LLC`,
  SUPPORT: "SUPPORT",
  SERVICES: "SERVICE",
  SUBSCRIBE: "Subscribe For Newsletter",
};

export const CONTACT_INFO_LIST = [
  {
    icon: CoMailOpen,
    label: "info@onemindservices.com",
    path: "mailto:info@onemindservices.com",
  },
  {
    icon: MdOutlineSupport,
    label: "support@onemindservices.com",
    path: "mailto:support@onemindservices.com",
  },

  {icon: FaPhoneAlt, label: "1-408-540-6771", path: "tel:1-408-540-6771"},
  {
    icon: FaLocationArrow,
    label: "11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    path: "https://maps.google.com?q=11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    ext_link: true,
  },
];

export const SERVICE_OPTIONS = [
  {
    name: "Cloud Strategy",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.CLOUD_STRATEGY}`,
  },
  {
    name: "Cloud Application",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.CLOUD_APPLICATION}`,
  },
  {
    name: "Observability",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.OBSERVABILITY}`,
  },
  {
    name: "Security Technologies",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.SECURITY_TECHNOLOGIES}`,
  },
  {
    name: "DRaaS",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.DRAAS}`,
  },
  {
    name: "Professional Services",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.PROFESSIONAL_SERVICES}`,
  },

  {
    name: "INFOSEC TRAINING",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.INFOSEC_TRAINING}`,
  },
  // {
  //   name: "IPv4 Request Form",
  //   path: `${MAIN_ROUTES.IPV4_REQUEST_FORM}`,
  // },
];

export const INTERNET_SERVICE = [
  {
    name: "IP TV Solutions",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.IP_TV_SOLUTIONS}`,
  },
  {
    name: "Business Internet ",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.BUSINESS_INTERNET}`,
  },
  {
    name: "Data Center Connectivity",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.INTERNET_SERVICE}${INTERNET_SERVICES_ROUTES.DATA_CENTER_CONNECTIVITY}`,
  },
];

export const SUPPORT_OPTIONS = [
  {name: "Home", path: "/"},
  {
    name: "About us",
    path: MAIN_ROUTES.ABOUT_US,
  },
  {
    name: "Careers",
    path: "http://careers.onemindservices.com/",
  },
  {
    name: "Contact us",
    path: MAIN_ROUTES.CONTACT_US,
  },
  {
    name: "Data Centers",
    path: MAIN_ROUTES.DATA_CENTER,
  },
  {
    name: "UC",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.VOICE_SERVICES}${VOICE_SERVICES_ROUTES.UNIFIED_COMMUNICATIONS}`,
  },
  {
    name: "Internet Of Things",
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.IOT}`,
  },
];

export const POLICIES_OPTION = [
  {
    name: "Privacy Policies",
    path: MAIN_ROUTES.PRIVACY_POLICIES,
  },
  {
    name: "VoIP Agreement",
    path: MAIN_ROUTES.VOIP_AGREEMENT,
  },

  {
    name: "911 Service Terms",
    path: MAIN_ROUTES.SERVICE_TERM,
  },
  {
    name: "Terms & Condition ",
    path: MAIN_ROUTES.TERM_CONDITION,
  },
  {
    name: "Accepted Use Policy ",
    path: MAIN_ROUTES.ACCEPTED_USE_POLICY,
  },
  {
    name: "Covid19 Advisory",
    path: MAIN_ROUTES.COVID_ADVISORY,
  },
  {name: "Indemnity Agreement", path: MAIN_ROUTES.INDEMNITY_AGREEMENT},
];
