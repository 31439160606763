import {switchAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(
  switchAnatomy.keys
);

const brand = definePartsStyle({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: "brand.100",
  },
  track: {
    bg: "white",
    _checked: {
      bg: "white",
    },
  },
});

export const Switch = defineMultiStyleConfig({
  variants: {brand},
});

export const SWITCH_VARIANTS = {BRAND: "brand"};
