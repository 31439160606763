import {MAIN_ROUTES} from "@/constants/routes";

export const HEADER_LINKS = [
  {
    name: "Data Centers",
    path: MAIN_ROUTES.DATA_CENTER,
  },
  // {
  //   name: "Catalog",
  //   path: MAIN_ROUTES.CATALOG,
  // },
  {
    name: "About Us",
    path: MAIN_ROUTES.ABOUT_US,
  },
  {
    name: "Careers",
    path: "http://careers.onemindservices.com/",
  },
  {
    name: "Contact Us",
    path: MAIN_ROUTES.CONTACT_US,
  },
];

export const HEADER_CONSTANTS = {
  MY_ACCOUNT: "My Account",
};

export const USER_LINKS = [
  {name: "Login / Sign Up", path: process.env.NEXT_PUBLIC_DASHBOARD_LOGIN},
];
