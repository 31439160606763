import {Box, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {FOOTER_CONSTANTS} from "../footer.constants";

const FooterSocial = () => {
  return (
    <Box background="#021b66" color="white" p={4} width="full">
      <Text textAlign={"center"} variant={TEXT_VARIANTS.LABEL1}>
        {FOOTER_CONSTANTS.RIGHTS}
      </Text>
    </Box>
  );
};

export default FooterSocial;
