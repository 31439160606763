"use client";
import {Fragment} from "react";
import Link from "next/link";
import {usePathname} from "next/navigation";
import {Link as ChakraLink, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import HeaderDropdown from "./headerDropdown";

type CreateLinkProps = {
  name: string;
  path?: string;
  onClick?: () => void;
  nestedRoutes?: {is_external?: boolean; name: string; path: string}[] | [];
};

const CreateLink = (props: CreateLinkProps) => {
  const {onClick = () => {}, name, path, nestedRoutes} = props;
  const pathname = usePathname();

  return (
    <Fragment>
      {nestedRoutes ? (
        <HeaderDropdown name={name} onClick={onClick} nestedRoutes={nestedRoutes} />
      ) : (
        <ChakraLink
          as={Link}
          color={pathname === path ? "brand.200" : ""}
          cursor={"pointer"}
          href={path}
          onClick={onClick}
          _hover={{color: "brand.200"}}
        >
          <Text variant={TEXT_VARIANTS.BODY1}>{name}</Text>
        </ChakraLink>
      )}
    </Fragment>
  );
};

export default CreateLink;
