"use client";

import React, {Fragment} from "react";
import Script from "next/script";
import {Analytics} from "@vercel/analytics/react";
import {ChakraProvider} from "@chakra-ui/react";
import MainLayout from "@/layouts/mainLayout";
import {theme} from "@/theme/index";
import "styles/app.scss";

export function Providers({children}: {children: React.ReactNode}) {
  return (
    <Fragment>
      <Script id="cookie-consent" type="text/javascript">
        {`
          var _iub = _iub || [];
          _iub.csConfiguration = {
            "enableCcpa":true,
            "countryDetection":true,
            "whitelabel":false,
            "lang":"en",
            "siteId":2680756,
            "cookiePolicyId":57406618,
            "enableTcf":true,
            floatingPreferencesButtonDisplay:"anchored-center-right",
              "banner":{
              "acceptButtonDisplay":true,
              "customizeButtonDisplay":true,
              "position":"float-bottom-left",
              "zIndex":99999998
            }
          };
        `}
      </Script>
      <Script type="text/javascript" src="//cdn.iubenda.com/cs/tcf/stub-v2.js"></Script>
      <Script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"
      ></Script>
      <Script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></Script>
      <Script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        async
      ></Script>

      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            duration: 6000,
            isClosable: true,
            position: "bottom-right",
          },
        }}
      >
        <MainLayout>{children}</MainLayout>
      </ChakraProvider>
      <Analytics />
    </Fragment>
  );
}
