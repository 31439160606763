import React from "react";
import {Grid, GridItem, Stack, SimpleGrid, Box} from "@chakra-ui/react";
import FooterBranding from "./components/footerBranding";
import FooterInternetServices from "./components/footerInternetServices";
import FooterPolicies from "./components/footerPolicies";
import FooterService from "./components/footerServices";
import FooterSocial from "./components/footerSocial";
import FooterSupport from "./components/footerSupport";
import dynamic from "next/dynamic";

const SafeView = dynamic(() => import("@/components/safeView"));

// import FooterSubscribe from "./components/footerSubscribe";
function Footer() {
  return (
    <Box backgroundColor="rgb(233, 233, 241)">
      <Stack>
        <SafeView>
          <Grid
            gap={{base: 4, md: 8}}
            p={{base: 3, sm: 6}}
            templateColumns={"repeat(3,1fr)"}
          >
            <GridItem colSpan={{base: 3, md: 1}}>
              <FooterBranding />
            </GridItem>
            <GridItem colSpan={{base: 3, md: 2}}>
              <Stack spacing={{base: 4, sm: 6}}>
                {
                  // <FooterSubscribe />
                }
                <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={{base: 4}}>
                  <FooterService />
                  <FooterInternetServices />
                  <FooterSupport />
                  <FooterPolicies />
                </SimpleGrid>
              </Stack>
            </GridItem>
          </Grid>
        </SafeView>
        <FooterSocial />
      </Stack>
    </Box>
  );
}

export default Footer;
