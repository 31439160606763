import Image from "next/image";
import {Stack, Heading, Box} from "@chakra-ui/react";
import logo from "public/brand/logo.png";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS} from "../footer.constants";
import FooterContactInfo from "./footerContactInfo";

const FooterBranding = () => {
  return (
    <Box
      backgroundColor="white"
      borderRadius="md"
      boxShadow="md"
      position={"relative"}
      px={6}
      py={12}
      top={{base: 0, md: -16}}
      width="full"
    >
      <Stack alignItems={{base: "center", sm: "start"}} spacing={4}>
        <Image alt="logo" src={logo} width={180} height={170} />
        <Heading
          fontWeight={400}
          textAlign={{base: "center", sm: "start"}}
          variant={HEADING_VARIANTS.SUBTITLE4}
        >
          {FOOTER_CONSTANTS.BRAND_TEXT}
        </Heading>
        <FooterContactInfo />
      </Stack>
    </Box>
  );
};

export default FooterBranding;
