import {Fragment} from "react";
import Image from "next/image";
import Link from "next/link";
// import {useRouter} from "next/router";
import {
  // Button,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
  useMediaQuery,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaBars,
  //  FaChevronDown,
  //  FaUserCircle
} from "@onemind-services-llc/react-icons-ng/fa";
import logo from "public/brand/logo.png";
import SafeView from "@/components/safeView";
import CreateLink from "./components/createLink";
import HeaderDrawer from "./components/headerDrawer";
import {
  // HEADER_CONSTANTS,
  HEADER_LINKS,
  //  USER_LINKS
} from "./header.constants";
import Services from "../services";
// import {BUTTON_VARIANTS} from "@/theme/components/button";

const Header = () => {
  const headerBackground = useColorModeValue("white", "gray.800");
  const iconColor = useColorModeValue("gray.600", "gray.200");
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isMobileOrIpad] = useMediaQuery("(max-width: 820px)");

  return (
    <Fragment>
      {isMobileOrIpad && isOpen && <HeaderDrawer isOpen={isOpen} onClose={onClose} />}
      <Box
        bg={headerBackground}
        boxShadow="0 10px 20px 0 rgba(0,0,0,.1)"
        height={"6rem"}
        left={0}
        position="fixed"
        right={0}
        top={0}
        zIndex="banner"
      >
        <SafeView>
          <Flex alignItems={"center"} height={"6rem"} justifyContent={"space-between"}>
            <Link href={"/"}>
              <Image alt="logo" src={logo} style={{width: "140px"}} />
            </Link>
            <HStack display={{base: "flex", md: "none"}} spacing={4}>
              {
                // <Button colorScheme={"brand.100"} variant={BUTTON_VARIANTS.BRAND}>
                //   {HEADER_CONSTANTS.MY_ACCOUNT}
                // </Button>
              }
              <IconButton
                aria-label={"drawer-toggle"}
                icon={<Icon as={FaBars} color={iconColor} />}
                onClick={onOpen}
              />
            </HStack>
            <HStack display={{base: "none", md: "flex"}} spacing={6}>
              <Services />
              {HEADER_LINKS.map((link, index) => (
                <CreateLink key={index} onClick={onClose} {...link} />
              ))}
              {
                // <Button colorScheme={"brand.100"} variant={BUTTON_VARIANTS.BRAND}>
                //   {HEADER_CONSTANTS.MY_ACCOUNT}
                // </Button>
              }
            </HStack>
          </Flex>
        </SafeView>
      </Box>
    </Fragment>
  );
};

export default Header;
