import Link from "next/link";
import {useRouter} from "next/router";
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {FaChevronDown} from "@onemind-services-llc/react-icons-ng/fa";
import {TEXT_VARIANTS} from "@/theme/components/text";

type Props = {
  name: string;
  nestedRoutes: {
    is_external?: boolean;
    name: string;
    path: string;
  }[];
  onClick?: () => void;
};

const HeaderDropdown = (props: Props) => {
  const {name, onClick, nestedRoutes} = props;
  const {asPath} = useRouter();
  const isActivePath = nestedRoutes.find((route) => route.path === asPath);
  return (
    <Menu>
      <MenuButton
        as={Button}
        backgroundColor={"transparent"}
        p={0}
        _active={{}}
        _focus={{}}
        _hover={{}}
      >
        <HStack alignItems="center">
          <Text
            color={isActivePath ? "brand.200" : ""}
            lineHeight={1}
            variant={TEXT_VARIANTS.BODY1}
          >
            {name}
          </Text>
          <Icon
            as={FaChevronDown}
            color={isActivePath ? "brand.200" : "gray.600"}
            fontSize="12px"
          />
        </HStack>
      </MenuButton>
      <MenuList>
        {nestedRoutes.map((route, index) => {
          return (
            <MenuItem
              as={Link}
              color={asPath === route.path ? "brand.200" : ""}
              href={route.path}
              key={index}
              onClick={onClick}
              target={route.is_external ? "_blank" : ""}
              _hover={{color: "brand.200"}}
            >
              <Text variant={TEXT_VARIANTS.BODY2}>{route.name}</Text>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default HeaderDropdown;
