import Link from "next/link";
import {Heading, Stack, Text} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, POLICIES_OPTION} from "../footer.constants";

const FooterPolicies = () => {
  return (
    <Stack spacing={{base: 2, sm: 4, md: 6}}>
      <Heading variant={HEADING_VARIANTS.SUBTITLE3}>{FOOTER_CONSTANTS.POLICIES}</Heading>
      <Stack>
        {POLICIES_OPTION.map((service) => (
          <Link href={service.path} key={service.path}>
            <Text color="#021b66">{service.name}</Text>
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};

export default FooterPolicies;
