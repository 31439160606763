import Link from "next/link";
import {Heading, Text, Stack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {FOOTER_CONSTANTS, SUPPORT_OPTIONS} from "../footer.constants";

const FooterSupport = () => {
  return (
    <Stack spacing={{base: 2, sm: 4, md: 6}}>
      <Heading variant={HEADING_VARIANTS.SUBTITLE3}>{FOOTER_CONSTANTS.SUPPORT}</Heading>
      <Stack color="#021b66">
        {SUPPORT_OPTIONS.map((option) => {
          const {name, path} = option;
          return (
            <Link href={path} key={path}>
              <Text>{name}</Text>
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FooterSupport;
